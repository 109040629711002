import React, { useState, useEffect, useRef,useCallback } from 'react';
import { useAuth } from '../contexts/AuthContext';
// import { useNavigate } from 'react-router-dom';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import DOMPurify from 'dompurify';
import CodeBlock from './CodeBlock';
import he from 'he';
import FileUpload from './FileUpload';   
import VoiceSearch from './VoiceSearch'; 

function Message({ role, content }) {
  const sanitizedContent = DOMPurify.sanitize(content);
  
  const renderContent = () => {
    const parts = sanitizedContent.split(/(<pre><code[\s\S]*?<\/code><\/pre>)/);
    return parts.map((part, index) => {
      if (part && part.startsWith('<pre><code')) {
        const languageMatch = part.match(/class=".*?language-(\w+)"/);
        const language = languageMatch ? languageMatch[1] : 'plaintext';
        const codeContent = part.replace(/<pre><code.*?>|<\/code><\/pre>/g, '');
        const decodedContent = he.decode(codeContent); // Use 'he' library to decode HTML entities
        return <CodeBlock key={index} language={language} code={decodedContent} />;
      }
      return <span key={index} dangerouslySetInnerHTML={{ __html: part }} />;
    });
  };

  return (
    <div className={`message ${role}`}>
      <span className="role">{role === 'user' ? 'You' : 'AI'}</span>
      <div>{renderContent()}</div>
    </div>
  );
}

function ModelSelector({ onSelect, selectedEngine }) {
  const engines = ['gemini-1.5-flash-001', 
                  'gemini-1.0-pro',
                  'chatgpt', 
                  'meta-llama-3-8b-instruct',
                  'mistralai/Mistral-7B-Instruct-v0.2',
                  'codellama/CodeLlama-13b-Instruct-hf',
                  'google/gemma-2-9b-it',
                  'claude-3-5-sonnet',
                  'Grok-AI'
  ];
  //, 'claude'
  useEffect(() => {
    if (!selectedEngine && engines.length > 0) {
      onSelect(engines[0]);
    }
  }, [selectedEngine, onSelect]);

  return (
    <select 
      className="model-selector" 
      onChange={(e) => onSelect(e.target.value)} 
      value={selectedEngine || engines[0]}
    >
      {engines.map(engine => (
        <option key={engine} value={engine}>
          {engine}
        </option>
      ))}
    </select>
  );
}

function Loader() {
  return (
    <div className="loader-container">
      <div className="loader">
        <div className="loader-dot"></div>
        <div className="loader-dot"></div>
        <div className="loader-dot"></div>
      </div>
    </div>
  );
}

function Chat() {
  const location = useLocation();
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [conversationId, setConversationId] = useState(null); 
  const [engine, setEngine] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const textareaRef = useRef(null);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [welcomeMessage, setWelcomeMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [error, setError] = useState(null);
  const [newChatStarted, setNewChatStarted] = useState(false);
  const [groupedChatHistory, setGroupedChatHistory] = useState({
    today: [],
    previous7Days: [],
    previous30Days: []
  });
  const [uploadedFileId, setUploadedFileId] = useState(null);
  // const [newConversationStarted, setNewConversationStarted] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProject] = useState(null);
  const [projectFiles, setProjectFiles] = useState([]);

  useEffect(() => {
    fetchProjects();
  }, []);


  const fetchProjects = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects`, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
          'X-Auth-Provider': user.provider
        }
      });
      if (!response.ok) throw new Error('Failed to fetch projects');
      const data = await response.json();
      setProjects(data);
    } catch (error) {
      console.error('Error fetching projects:', error);
      setError('Failed to fetch projects. Please try again.');
    }
  };

  const fetchProjectFiles = async (projectId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${projectId}/files`, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
          'X-Auth-Provider': user.provider
        }
      });
      if (!response.ok) throw new Error('Failed to fetch project files');
      const data = await response.json();
      setProjectFiles(data);
    } catch (error) {
      console.error('Error fetching project files:', error);
      setError('Failed to fetch project files. Please try again.');
    }
  };

  

  const [selectedProjectId, setSelectedProjectId] = useState(null);

  // useEffect(() => {
  //   if (location.state?.selectedProjectId) {
  //     setSelectedProjectId(location.state.selectedProjectId);
  //     handleProjectSelect(location.state.selectedProjectId);
  //   }
  // }, [location]);
  const [inputValue, setInputValue, pidval] = useState('');


  const handleProjectSelect = useCallback(async (projectId) => {
    navigate(location.pathname, { replace: true, state: {} });
    setSelectedProjectId(projectId);
    if(projectId){
      setInputValue(projectId);
    } else {  setInputValue('');  }
    setTimeout(async () => {
      var inputValue = document.getElementById('projectInput').value;
      if (engine) {
        await startNewConversation(engine, inputValue);
      }
    }, 1000);
  }, [engine]);





  useEffect(() => {
    setWelcomeMessage("Welcome to AI Chat. Please select a model to begin.");
    if (location.state && location.state.pid) { 
      var pidval = location.state.pid;
      // navigate(location.pathname, { replace: true, state: {} });
    }  
  }, []);

  useEffect(() => {
    if (engine) {
      setWelcomeMessage(`Switched to ${engine}. How can I assist you?`);
      setMessages([]); // Clear previous messages when model changes
      setConversationId(null); // Reset conversation ID
    }
  }, [engine]);


  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages, isLoading]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'inherit';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [input]);

  useEffect(() => {
    fetchChatHistory();
  }, [user]);

  const fetchChatHistory = useCallback(async () => {
    console.log("Fetching chat history");
    setIsLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/conversationshistory`, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
          'X-Auth-Provider': user.provider
        }
      });
      if (!response.ok) throw new Error('Failed to fetch chat history');
      const data = await response.json();
      console.log("Chat history fetched:", data);
      groupChatHistory(data);
    } catch (error) {
      console.error('Error fetching chat history:', error);
      setError('Failed to fetch chat history. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  const groupChatHistory = (chatHistory) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
    const thirtyDaysAgo = new Date(today);
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

    const grouped = chatHistory.reduce((acc, chat) => {
      const chatDate = new Date(chat.created_at);
      if (chatDate >= today) {
        acc.today.push(chat);
      } else if (chatDate >= sevenDaysAgo) {
        acc.previous7Days.push(chat);
      } else if (chatDate >= thirtyDaysAgo) {
        acc.previous30Days.push(chat);
      }
      return acc;
    }, { today: [], previous7Days: [], previous30Days: [] });

    setGroupedChatHistory(grouped);
  };


  const selectConversation = async (selectedConversationId) => {
    setIsLoading(true);
    setConversationId(selectedConversationId);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/conversationshistory/${selectedConversationId}/messages`, {
        headers: {
          'Authorization': `Bearer ${user.token}`,
          'X-Auth-Provider': user.provider
        }
      });
      if (!response.ok) throw new Error('Failed to fetch conversation messages');
      const data = await response.json();
      setMessages(data);
      // setEngine(data[0]?.engine || null); // Set engine from the first message, if available
      setWelcomeMessage(''); // Clear welcome message when loading a conversation
    } catch (error) {
      console.error('Error fetching conversation messages:', error);
      setMessages([]);
      setWelcomeMessage('Failed to load conversation. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };



  const startNewConversation = useCallback(async (selectedEngine) => {
      setIsLoading(true);
    // setEngine(selectedEngine);
    setMessages([]);
    setConversationId(null); 
    setIsLoading(true);
    setWelcomeMessage(`Starting new conversation with ${selectedEngine}...`);
    setNewChatStarted(true); 
    try {
      var inputValuenew = document.getElementById('projectInput').value;
      const payload = { engine: selectedEngine };
      if (inputValuenew) {
        payload.projectId = inputValuenew;
      }
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/conversations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`,
          'X-Auth-Provider': user.provider
        },
        body: JSON.stringify(payload)
        // body: JSON.stringify({ engine: selectedEngine, projectId: selectedProjectId })
      });
      if (!response.ok) {
        if(response.statusText == "Unauthorized"){
          console.log('Token expired. Logging out...');
          logout();
          navigate('/login');
        }
        throw new Error('Failed to start conversation');
      }
      const data = await response.json();
      setConversationId(data.conversationId);
      setWelcomeMessage(`New conversation started with ${selectedEngine}. How can I assist you?`);
    } catch (error) {
      console.error('Error starting conversation:---', error.message);
      // Handle error (e.g., show error message to user)
    } finally {
      setIsLoading(false);
      setNewChatStarted(false);
    }
  }, [user,navigate, logout, selectedProjectId]);
 

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!input.trim() || !conversationId || isLoading) return;

    const newMessage = { role: 'user', content: input };
    setMessages(prev => [...prev, newMessage]);
    setInput('');
    setIsLoading(true);

    try {
      var inputValuenew = document.getElementById('projectInput').value;
      console.log(inputValuenew+"---------------------------------------");
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/conversations/${conversationId}/messages`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`,
          'X-Auth-Provider': user.provider
        },
        // body: JSON.stringify({ message: input, fileId: uploadedFileId })
        body: JSON.stringify({ 
          message: input, 
          projectId: inputValuenew,
          fileIds: uploadedFileId
        })
      });
      if (!response.ok) {
        throw new Error('Failed to send message');
      }
      const data = await response.json();
      
      // setMessages(prev => [...prev, { role: 'assistant', content: data.response }]);
      var aiMessage;
      // if (engine === 'meta-llama-3-8b-instruct') {
      //   const parsedResponse = JSON.parse(data.response);
      //   aiMessage = { 
      //     role: 'assistant', 
      //     content: parsedResponse.text,
      //     metadata: parsedResponse.metadata
      //   };
      // } else { }
        aiMessage = { role: 'assistant', content: data.response };
      
      
      setMessages(prev => [...prev, aiMessage]);

    } catch (error) {
      console.error('Error sending message:', error);
      setMessages(prev => [...prev, { role: 'error', content: 'Failed to send message. Please try again.' }]);
    } finally {
      setIsLoading(false);
    }
  };

 const handleVoiceTranscription = useCallback((transcription) => {
    setInput(transcription);
  }, []);

  const handleTranscriptionComplete = useCallback(() => {
    if (input.trim() && !isLoading) {
      sendMessage({ preventDefault: () => {} });
    }
  }, [input, isLoading, sendMessage]);


  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage(e);
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const toggleProfileMenu = () => {
    setShowProfileMenu(!showProfileMenu);
    console.log(user)
  };

  const handleModelChange = useCallback(async (selectedEngine) => {
    console.log("Model changed to:", selectedEngine);
    setEngine(selectedEngine);
    setMessages([]);
    setConversationId(null);
    setUploadedFileId(null); 
    await startNewConversation(selectedEngine);
    await fetchChatHistory();
  }, [fetchChatHistory]);


  const handleNewChat = useCallback(async () => {
    console.log("new Chat......")
    if (engine) {
      setMessages([]);
      setInput('');
      setFileId(null);   
      setFileName(null);  
      setUploadedFileId(null); 
      await startNewConversation(engine);
      await fetchChatHistory();
    } else {
      setError("Please select a model to start a new chat.");
    }
  }, [engine, startNewConversation, fetchChatHistory]);


  const renderChatGroup = (title, chats) => (
    <div className="chat-group">
      <h4>{title}</h4>
      <ul>
        {chats.map((chat) => (
          <li 
            key={chat.id} 
            onClick={() => selectConversation(chat.id)}
            className={conversationId === chat.id ? 'active' : ''}
          >
            {/* <div className="chat-history-item">
              <span className="chat-date">{formatDate(chat.created_at)}</span>
            </div> */}
            {chat.first_user_message && (
              <div className="chat-preview" 
                   dangerouslySetInnerHTML={{ 
                     __html: DOMPurify.sanitize(truncateText(chat.first_user_message, 50)) 
                   }} 
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );

  const [fileId, setFileId] = useState(null);
  const [fileName, setFileName] = useState(null);

  const handleFileUpload = (fileId, fileName) => {
    setUploadedFileId(fileId);
    setMessages(prev => [...prev, { role: 'system', content: `File uploaded: ${fileName}` }]);
  };
  const [pid, setPid] = useState(null);




  return (
    <div className="container">
      <div className="row">
      <div className="col-lg-2 left-column">
          <div className="left-column-top">
            <h2>AI Chat</h2>
            <button onClick={handleNewChat} className="new-chat-button">New Chat</button>
            <ModelSelector onSelect={handleModelChange} selectedEngine={engine} /> 
            <Link to="/projects" className="project-management-link">Project Management</Link>
            {/* <ModelSelector onSelect={handleModelChange} selectedEngine={engine} /> */}
            <div className="chat-history">
              <h3>Recent Conversations</h3>
              {groupedChatHistory.today.length > 0 && renderChatGroup("Today", groupedChatHistory.today)}
              {groupedChatHistory.previous7Days.length > 0 && renderChatGroup("Previous 7 Days", groupedChatHistory.previous7Days)}
              {groupedChatHistory.previous30Days.length > 0 && renderChatGroup("Previous 30 Days", groupedChatHistory.previous30Days)}
              {Object.values(groupedChatHistory).every(group => group.length === 0) && (
                <p>No conversations yet. Start a new chat to begin!</p>
              )}
            </div>
          </div>

          <div className="project-section">
            <h3>Projects</h3>
            <select 
              className="project-select"
              value={(location.state)?location.state.pid:''} 
              onChange={(e) => handleProjectSelect(e.target.value)}
            >
              <option value="">Select a project</option>
              {projects.map(project => (
                <option key={project.id} value={project.id}>{project.name}</option>
              ))}
            </select>
            <input 
              type="text" 
              id="projectInput"
              // value={inputValue} 
              value={location.state?.pid ? location.state.pid : inputValue} 
              onChange={(e) => setInputValue(e.target.value)} // Allows manual editing of input if needed
              placeholder="Project name will appear here"
            />
          </div>

          <div className="left-column-bottom">
        
          
            <button onClick={toggleProfileMenu} className="profile-button">
              <div className="profile-info">
                <span className="profile-name">{user.userData.name || 'User'}</span>
                <span className="profile-role">{user.userData.role || 'Guest'}</span>
              </div>
            </button>
            {showProfileMenu && (
              <div className="profile-menu">
                <p>{user.userData.email}</p>
                {user.userData.role === 'admin' && (
                  <>
                    <Link to="/user-management" className="user-management-link">User Management</Link>
                    <Link to="/all-chat-history" className="chat-history-link">Chat History</Link>
                  </>
                )}
                  <p></p>
                <button onClick={handleLogout}>Logout</button>
              </div>
            )}
          </div>
        </div>
        <div className="col-lg-10 right-column">
          <div className="chat-container">
          {welcomeMessage && (
              <div className="welcome-message">
                {welcomeMessage}
              </div>
            )}
            {messages.map((message, index) => (
              <Message key={index} {...message} />
            ))}
            {isLoading && <Loader />}
            <div ref={messagesEndRef} />
          </div>
          <form onSubmit={sendMessage} className="input-form">
            <textarea
              ref={textareaRef}
              value={input}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown} 
              placeholder={selectedProject ? `Ask about ${selectedProject.name}...` : "Type your message..."}
              disabled={!engine || isLoading}
              // disabled={!engine || isLoading || !selectedProject}
              rows="1"
            /> 
             <div className="file-upload-section"> 
              <FileUpload onFileUpload={handleFileUpload} conversationId={conversationId} newChatStarted={newChatStarted} />
             {/* {fileName && <div className="file-name">Current file: {fileName}</div>} */}
             </div>
             <VoiceSearch onTranscription={handleVoiceTranscription} onTranscriptionComplete={handleTranscriptionComplete} />
            <button type="submit" disabled={!engine || !input.trim() || isLoading}>
              {isLoading ? 'Sending...' : 'Send'}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
 
// Helper function to truncate text
function truncateText(text, maxLength) {
  if (text.length <= maxLength) return text;
  return text.substr(0, maxLength) + '...';
}

// Helper function to format date
function formatDate(dateString) {
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return new Date(dateString).toLocaleDateString(undefined, options);
}

export default Chat;